.div-ui-container {
    width: 100%;
    height: 70vh;
}

.div-results-container {
    width: 100%;
    height: 10vh;
    overflow: auto;
}
