@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(359deg);
    }
}

.circle-core {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-inner {
    background-color: #f3f3f3;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    position: relative;
    align-content: center;
    justify-content: center;
}

.slogan {
    color: #102754;
    font-family: "DM Serif Display", serif;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 2rem;
}

.logo-spinner .icon img {
    width: 42px;
    height: 42px;
}

.loadingPage {
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: repeat;
}

.logo-spinner {
    height: 100px;
    width: 100px;
    position: relative;
}

.icon {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4px;
    padding-left: 5px;
}

.circle-border {
    width: 100px;
    height: 100px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #156fbe;
    background: linear-gradient(0deg, #4995d7 33%, #61d690 100%);
    animation: spin 0.8s linear 0s infinite;
    opacity: 0.8;
}

.circle-core {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
}
